import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-awesome-styled-grid"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteAuthor = this.props.data.site.siteMetadata.author
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />       
      <Container>
        <h2 style={{fontFamily: `"Open Sans"`, textAlign: `center`, marginTop: `2rem`, marginBottom: `0.5rem`}}>{post.frontmatter.title}</h2>
        <p style={{textAlign: `center`, fontSize: `0.8rem`, fontWeight: `lighter`, marginBottom: `0`}}>By {siteAuthor}</p>
        <p style={{textAlign: `center`, fontSize: `0.8rem`, fontWeight: `lighter`, marginBottom: `1rem`}}>{post.frontmatter.date}</p>
        <Row justify="space-around">
          <Col xs={6} sm={6} md={6} align='justify' style={{display: `flex`, flexDirection: `column`, textAlign: `justify`}}>
          <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} />
            <MDXRenderer style={{textAlign: `center`}}>{post.body}</MDXRenderer>
          </Col>
        </Row>
        <hr
          style={{
            marginTop: '3rem',
            marginBottom: '3rem',
          }}
        />
        <Row justify="space-around">
          <Col xs={6} sm={6} md={4} style={{display: `contents`, flexDirection: `column`}}>
           <Bio/>
          </Col>
        </Row>

          <ul
            style={{
              marginTop: `3rem`,
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}
          >
            <li>
              {previous && (
                <Link to={`blog${previous.fields.slug}`} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link to={`blog${next.fields.slug}`} rel="next">
                  {next.frontmatter.title} →
                </Link>
              )}
            </li>
          </ul>
      </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: {eq: $slug}}, frontmatter: {collection: {eq: "blog-post"}}) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "DD MMM, YYYY")
        description
        collection
        thumbnail{
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
